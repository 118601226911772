import { BrickSetup } from 'components/bricks/types/brick.type';

const setup: BrickSetup = {
    subType: 'landingpage',
    title: 'Landing page',
    channel: 'web',
    description: 'Create a landing page.',
    addBrickText: 'Add landing page',
    availableParentBricks: [],
    availableChildBricks: [],
    outputAction: 'publish',
    defaultTab: 'landingpage',
    tabs: [
        {
            key: 'settings',
            title: 'Settings',
            type: 'multiInput'
        },
        {
            key: 'landingpage',
            title: 'Landing page',
            type: 'component',
            component: 'preview'
        },
        {
            key: 'briefing',
            title: 'Briefing',
            type: 'component',
            component: 'briefing'
        },
        {
            key: 'publish',
            title: 'Publish',
            type: 'component',
            component: 'output'
        }
    ]
};

export default setup;
