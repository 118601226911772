const settings: any[] = [
    {
        title: 'General',
        path: 'general',
        type: 'page',
        showLanguageSelector: false,
        multiLanguage: false,
        tour: false,
        blocks: [
            {
                title: 'General',
                itemType: 'block',
                type: 'multiInput',
                opened: true,
                items: [
                    {
                        type: 'text',
                        title: 'Block title',
                        model: 'title',
                        itemType: 'input',
                        label: 'Title',
                        maxLength: 150,
                        fixedModel: true,
                        useValueObject: false
                    },
                    {
                        type: 'select',
                        model: 'data.settings.abTesting.test',
                        label: 'AB testing',
                        defaultValue: 'none',
                        options: {
                            none: 'No A/B testing',
                            ab: '2 Groups (A/B testing)',
                            abc: '3 Groups (A/B/C testing)',
                            abcd: '4 Groups (A/B/C/D testing)'
                        }
                    }
                ]
            },
            {
                title: 'Planning',
                itemType: 'block',
                type: 'multiInput',
                opened: true,
                items: [
                    {
                        type: 'date',
                        model: 'dateStart',
                        label: 'Start date'
                    },
                    {
                        type: 'date',
                        model: 'dateEnd',
                        label: 'End date'
                    }
                ]
            }
        ]
    }
];

export default settings;
