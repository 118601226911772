import { IEditorBlock } from 'components/bricks/types/editorBlock.type';

const settings: IEditorBlock[] = [
    {
        title: 'General',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'text',
                model: 'settings.title',
                itemType: 'input',
                label: 'Title',
                fixedModel: true,
                maxLength: 150,
                useValueObject: false
            }
        ]
    },
    {
        title: 'Publish',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'metadataInput',
                model: 'account',
                identifier: 'linkedin_campaign_group-account',
                label: 'Ad account id input',
                metadataType: 'linkedInAdAccount',
                config: {
                    q: 'search'
                },
                useValueObject: false
            }
        ]
    },
    {
        title: 'Group budget and schedule',
        itemType: 'block',
        type: 'multiInput',
        blockModel: '{{{MODEL_PUBLISH_METADATA}}}',
        opened: true,
        items: [
            {
                type: 'radioList',
                model: 'delivery_strategy',
                identifier: 'linkedin_campaign_group-delivery_strategy',
                label: 'Delivery',
                options: [
                    {
                        key: 'continuously',
                        value: 'Run continuously from a start date'
                    },
                    {
                        key: 'start_end',
                        value: 'Set a start and end date'
                    },
                    {
                        key: 'start_end_budget',
                        value: 'Set a start and end date with a budget'
                    }
                ]
            },
            {
                type: 'date',
                model: 'runSchedule.start',
                identifier: 'linkedin_campaign_group-run_schedule-start',
                label: 'Start date'
            },
            {
                type: 'date',
                model: 'runSchedule.end',
                identifier: 'linkedin_campaign_group-run_schedule-end',
                condition: 'blockData.delivery_strategy!=="continuously"',
                label: 'End date'
            },
            {
                type: 'select',
                model: 'totalBudget.currencyCode',
                label: 'Budget currency code',
                identifier: 'linkedin_campaign_group-total_budget-currency_code',
                condition: 'blockData.delivery_strategy==="start_end_budget"',
                options: {
                    BGN: 'Bulgarial lev',
                    EUR: 'Euro',
                    USD: 'Dollar'
                }
            },
            {
                type: 'number',
                tooltip: 'Campaign group budget',
                identifier: 'linkedin_campaign_group-total_budget-amount',
                condition: 'blockData.delivery_strategy==="start_end_budget"',
                model: 'totalBudget.amount',
                label: 'Daily budget'
            }
            //TODO: To be discussed
            // {
            //     type: 'checkboxList',
            //     model: 'settings.publish.channels.linkedin.allowedCampaignTypes',
            //     label: 'Allowed campaign types',
            //     tooltip: 'Array of enums that indicates allowed campaign types within the specific campaign group.',
            //     selectType: 'multiple',
            //     options: {
            //         TEXT_AD: 'Text ad',
            //         SPONSORED_UPDATES: 'Sponsored updates',
            //         SPONSORED_INMAILS: 'Sponsored inmails',
            //         DYNAMIC: 'Dynamic'
            //     }
            // }
        ]
    }
];

export default settings;
