import { IEditorBlock } from 'components/bricks/types/editorBlock.type';

const metadata: IEditorBlock[] = [
    {
        type: 'subSection',
        opened: true,
        title: 'Metadata',
        items: [
            {
                type: 'textMultiLanguage',
                model: 'subject',
                label: 'Subject'
            },
            {
                type: 'textMultiLanguage',
                model: 'snippet',
                label: 'Snippet'
            }
        ]
    }
];

export default metadata;
