import { IEditorBlock } from 'components/bricks/types/editorBlock.type';

const settings: IEditorBlock[] = [
    {
        title: 'General',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'text',
                title: 'Block title',
                model: 'settings.title',
                itemType: 'input',
                label: 'Title',
                fixedModel: true,
                maxLength: 150,
                useValueObject: false
            }
        ]
    },
    {
        title: 'Details',
        itemType: 'block',
        type: 'multiInput',
        blockModel: '{{{MODEL_PUBLISH_METADATA}}}',
        opened: true,
        items: [
            {
                type: 'metadataInput',
                model: 'account',
                identifier: 'linkedin_ad-account',
                label: 'Ad account id input',
                metadataType: 'linkedInAdAccount',
                config: {
                    q: 'search'
                },
                useValueObject: false
            },
            {
                type: 'select',
                model: 'intendedStatus',
                identifier: 'linkedin_ad-intended_status',
                label: 'Status',
                options: {
                    ACTIVE: 'Active',
                    PAUSED: 'Paused'
                }
            }
        ]
    }
];

export default settings;
