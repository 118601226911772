import { BrickSetup } from 'components/bricks/types/brick.type';

const setup: BrickSetup = {
    subType: 'linkedin_campaign',
    title: 'LinkedIn campaign',
    channel: 'social',
    platform: 'linkedin',
    description: 'Create a campaign for a LinkedIn campaign group',
    addBrickText: 'Add campaign',
    outputAction: 'publish',
    availableParentBricks: ['linkedin_campaignGroup'],
    availableChildBricks: ['linkedin_ad'],
    tabs: [
        {
            key: 'settings',
            title: 'Settings',
            type: 'multiInput' // gets settings.ts
        },
        {
            key: 'preview',
            title: 'Preview',
            type: 'component',
            component: 'preview'
        },
        {
            key: 'briefing',
            title: 'Briefing',
            type: 'component',
            component: 'briefing'
        }
    ]
};

export default setup;
