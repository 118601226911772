import React, { useState, useMemo } from 'react';
import Icon from 'components/ui-components-v2/Icon';
import VisualStackEditorHelpers from 'components/data/VisualStackEditorHelpers';
import EditorData from 'components/editor-data/EditorData';
import Translation from 'components/data/Translation';
import TabWrapper from 'components/bricks/components/shared/components/tab-wrapper';
import Button from 'components/ui-components-v2/Button';
import { VisualStackEditorAbTestTabbar, VisualStackEditorAlertBox } from 'components/ui-base/VisualStackEditor';
import LandingpageEditor from 'components/channels/landingpage/LandingpageEditor';
import { LandingpagePreviewRenderDynamicData, LandingpagePreviewViewSwitch } from 'components/channels/landingpage/LandingpagePreview';
import Illustration from 'components/ui-components-cape/Illustration';
import EmptyState from 'components/ui-components-cape/EmptyState';
import './styles/preview.scss';

interface Props {
    blockModel: string;
}

const getHasContent = (blockModel: string) => {
    const value = EditorData.getValueFromModel(`${blockModel}.data.setup`);
    return !!value?.length;
};

const LandingPagePreview: React.FC<Props> = ({ blockModel }) => {
    const [editorOpen, setEditorOpen] = useState<boolean>(false);
    const [viewWidth, setViewWidth] = useState<number>(1250);
    const groupKey = useMemo(() => EditorData.getValueFromModel(`${blockModel}.data.groupKey`), []);
    const hasContent = useMemo(() => getHasContent(blockModel), [editorOpen]);
    const abTesting = VisualStackEditorHelpers.getAbTestSetting(`${blockModel}.data.settings`);
    const [abTestGroupsShown, setAbTestGroupsShown] = useState(abTesting ? [...abTesting] : false);

    return (
        <React.Fragment>
            <TabWrapper>
                <div className="bricks-landingpage-preview">
                    {hasContent ? (
                        <React.Fragment>
                            <div className="bricks-landingpage-preview__toolbar">
                                <div>
                                    {abTesting && (
                                        <VisualStackEditorAbTestTabbar
                                            abTesting={abTesting}
                                            onSetAbTestGroupsShown={setAbTestGroupsShown}
                                            abTestGroupsShown={abTestGroupsShown}
                                            className="bricks-landingpage-preview__ab-testing"
                                        />
                                    )}
                                </div>
                                <Button onClick={() => setEditorOpen(true)} variant="contained" startIcon={<Icon>edit</Icon>}>
                                    Open editor
                                </Button>
                            </div>
                            <div className="bricks-landingpage-preview__content">
                                <div className="bricks-landingpage-preview__preview">
                                    <VisualStackEditorAlertBox
                                        abTesting={abTesting}
                                        abTestGroupsShown={abTestGroupsShown}
                                        className="bricks-landingpage-preview__alert"
                                    />
                                    <div className="bricks-landingpage-preview__switch-container">
                                        <LandingpagePreviewViewSwitch viewWidth={viewWidth} onSetViewWidth={setViewWidth} />
                                    </div>
                                    {!editorOpen && (
                                        <LandingpagePreviewRenderDynamicData
                                            data={{ model: `${blockModel}.data.setup` }}
                                            additionalProps={{
                                                model: `${blockModel}.data.setup`,
                                                dataModel: `${blockModel}.data.data`,
                                                settingsModel: `${blockModel}.data.settings`,
                                                iFrameWidth: viewWidth,
                                                // previewUrl: 'https://klm-landingpage-acceptance.bycape.io'
                                                previewUrl: 'http://localhost:3000',
                                                abTesting,
                                                abTestGroupsShown,
                                                onOpenEditor: () => setEditorOpen(true)
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        </React.Fragment>
                    ) : (
                        <EmptyState
                            primaryText={Translation.get('visualStackEditor.placeholder.title', 'editor')}
                            secondaryText={Translation.get('visualStackEditor.placeholder.subtitle', 'editor')}
                            illustration={<Illustration illustration="fallback" />}
                            primaryButton={{
                                label: Translation.get('visualStackEditor.placeholder.actionCreate', 'editor'),
                                onClick: () => setEditorOpen(true)
                            }}
                        />
                    )}
                </div>
            </TabWrapper>
            {editorOpen && (
                <LandingpageEditor
                    additionalProps={{
                        onClose: () => setEditorOpen(false),
                        dataModel: `${blockModel}.data.data`,
                        groupKey
                    }}
                    data={{
                        model: `${blockModel}.data.setup`,
                        dataModel: `${blockModel}.data.data`,
                        sourceDataModel: `${blockModel}.data.data`,
                        settingsModel: `${blockModel}.data.settings`,
                        // previewUrl: 'https://klm-landingpage-acceptance.bycape.io'
                        previewUrl: 'http://localhost:3000'
                    }}
                />
            )}
        </React.Fragment>
    );
};

export default LandingPagePreview;
