import { BrickSetup } from 'components/bricks/types/brick.type';

const setup: BrickSetup = {
    subType: 'linkedin_campaignGroup',
    title: 'LinkedIn campaign group',
    channel: 'social',
    platform: 'linkedin',
    description: 'Create a LinkedIn campaign group',
    addBrickText: 'Add campaign group',
    outputAction: 'publish',
    availableParentBricks: [],
    availableChildBricks: ['linkedin_campaign'],
    tabs: [
        {
            key: 'settings',
            title: 'Settings',
            type: 'multiInput' // gets settings.ts
        },
        {
            key: 'preview',
            title: 'Preview',
            type: 'component',
            component: 'preview'
        },
        {
            key: 'briefing',
            title: 'Briefing',
            type: 'component',
            component: 'briefing'
        }
    ]
};

export default setup;
